module.exports = new Promise((resolve, reject) => {
        let packageName = "base";
        let remote = "base";
        let kind = "web";
        let versionNumber = window.dependent_versionNumber[remote];
        let scriptId = remote+versionNumber;
        const existingScript = document.getElementById(scriptId);

        function onScriptLoad(){
          console.log(packageName ,'downloaded ::','for parent',"main");
          resolve(window[packageName]);
        }
        function onScriptLoadError(){
          document.head.removeChild(existingScript);
          reject();
        }

        if(window[packageName]){
          
          resolve(window[packageName]);
          
        } else if (existingScript) {

          existingScript.addEventListener('load', onScriptLoad);
          existingScript.addEventListener('error', onScriptLoadError);

        } else {

          let remoteUrl = window.publicPath+"static/"+remote+"/"+kind+"/"+versionNumber+"/external.js";

          const script = document.createElement("script");
          script.src = remoteUrl;
          script.type = "text/javascript";
          script.async = true;
          script.id = scriptId;

          script.addEventListener('load', onScriptLoad);
          script.addEventListener('error', onScriptLoadError);
      
          document.head.appendChild(script);
        }
      });