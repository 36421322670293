/* eslint-disable no-undef */

import { isLocalhost } from "@main/common/utils/index.js";
//set the resource public path dynamically
/* eslint-disable camelcase */
__webpack_public_path__ = window.publicPath;
console.warn(
  "%cWarning\n%c%s",
  "font-weight: bold; font-size: 28px; color: red; background-color: yellow;",
  "font-weight: normal; font-size: 20px; color: white",
  // eslint-disable-next-line max-len
  "This is a browser feature intended for developers. Using this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS. Do not enter or paste code that you do not understand."
);
// if (process.env.NODE_ENV === "production" && isLocalhost) {
//   window.dependent_versionNumber = mockServiceBuildNumber || {};
//   const rootServices = ["base", "account", "widgets"];
//   const loadedScripts = [];
//   const services = Object.keys(window.dependent_versionNumber).filter((serviceName) =>
//     rootServices.includes(serviceName)
//   );

//   services.forEach((serviceName) => {
//     const script = document.createElement("script");
//     script.src = `/static/${serviceName}/${window.dependent_versionNumber[serviceName]}/web/external.js`;
//     script.onload = () => {
//       loadedScripts.push(serviceName);
//       loadApp(loadedScripts.length === 3);
//     };
//     document.head.appendChild(script);
//   });
// } else {
//   loadApp(true);
// }

// function loadApp(show) {
//   if (show) {
//     import("./bootstrap.jsx");
//   }
// }

if (isLocalhost) {
  fetch("/id")
    .then((res) => {
      return res.json();
    })
    .then((accountdata) => {
      window.accountDetails = accountdata;
    })
    .catch((error) => {
      console.info("ID api::Error", error);
    })
    .finally((err) => {
      import("./bootstrap.jsx");
    });
} else {
  import("./bootstrap.jsx");
}
