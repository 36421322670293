export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // localhost with subdomain
    window.location.hostname.split(".")[1] === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    // Also Regex added for ip address starting with 192, so that Ip can access localhost in mobile devices
    window.location.hostname.match(
      /^(127|192)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
